import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { AppConfig } from "./app.config";
import { SharedModule } from "./shared/shared.module";
import { UnauthorizedRequestInterceptor } from "./shared/http-interceptor.service";
import { GlobalService } from "./shared/services/global.service";
import {
  AuthService,
  AuthGuard,
  AuthModule,
  AuthConfigService,
  AuthHttpInterceptor,
} from "@auth0/auth0-angular";
import { ConflowSettings } from "./shared/settings/conflow-settings";

export const initializeApp = (appConfig: AppConfig) => {
  return () => appConfig.load();
};

export const initializeSpace = () => {
  // set userSpaceID value in localStorage (last selected space ID) to sessionStorage
  return () => {
    return new Promise<void>((resolve) => {
      if (!sessionStorage.getItem("userSpaceID")) {
        sessionStorage.setItem("userSpaceID", localStorage.getItem("userSpaceID"));
      }
      resolve();
    });
  };
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot(),
    CoreModule.forRoot(),
    AuthModule.forRoot()
  ],
  providers: [
    AuthService,
    GlobalService,
    AuthGuard,
    AppConfig,
    CoreModule,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSpace,
      deps: [],
      multi: true,
    },
    {
      provide: AuthConfigService,
      useFactory: () => ({
        domain: ConflowSettings.domain,
        clientId: ConflowSettings.clientId,
        authorizationParams: {
          /* eslint-disable camelcase */
          redirect_uri: `${window.location.origin}/logincallback`,
          ui_locales: localStorage.getItem("localStoreLang"),
          /* eslint-enable camelcase */
          audience: ConflowSettings.audience,
          httpInterceptor: {
            allowedList: [`${ConflowSettings.audience}*`],
          },
        }
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ] })
export class AppModule {}
