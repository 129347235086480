@use 'src/app/shared/scss/conflow' as conflow;

app-login {

    #login {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: stretch flex-start;
        align-items: stretch;

        #login-intro {
            padding: 20px;
            flex: 1 1 0%;
            box-sizing: border-box;

            @include conflow.media-breakpoint('lt-lg') {
                display: none;
            }

            @include conflow.media-breakpoint('lt-se-h') {
                display: none;
            }

            .logo {
                
                .login-logoicon {
                    display: inline-block;
                    width: 36px;
                    height: 36px; 
                    text-indent: -9999px;   
                    background-repeat: no-repeat;
                    background-size: 36px 36px !important;
                    padding-top: 14px;
                    background: url('/assets/images/logos/conflow_black.svg') no-repeat;
                }

                .login-logotext {
                    font-size: 30px;
                    font-weight: bold;
                }
            }

            .description {
                padding-top: 100px;
            }
        }

        #login-form-wrapper {
            overflow: hidden !important;
            width: 400px;
            min-width: 400px;
            max-width: 400px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            max-height: 680px;
            margin-top: 160px;
            margin-right: 3%;
            background: #fff;
           @include conflow.elevation(16);

            @include conflow.media-breakpoint('xs') {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                margin: 0 !important;
                height: 100%;
                max-height: 100%;
            }

            @include conflow.media-breakpoint('sm') {
                width: 320px;
                min-width: 320px;
                max-width: 320px;
                max-height: 680px;
            }

            @include conflow.media-breakpoint('lt-lg') {
                margin: 160px auto 100px auto;
            }

            @include conflow.media-breakpoint('lt-se-h') {
                margin: 0;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            #login-form {
                padding: 128px 48px 48px 48px;

                @include conflow.media-breakpoint('xs') {
                    text-align: center;
                    padding: 24px;
                }

                @include conflow.media-breakpoint('lt-lg') {
                    padding: 48px;
                }

                .logo {
                    margin: 20px auto;
                    width: 80px;
                    width: 128px;
                    margin: 0 auto 18px auto;

                    .login-form-logoicon {
                        width: 120px;
                        height: 100px;
                        background: url('/assets/images/logos/conflow.svg') no-repeat;
                    }
                    .login-form-logotext {
                        box-sizing: border-box;
                        margin-top: 20px;
                        height: 30px;
                        background-size: 120px 30px !important;
                        background: url('/assets/images/logos/divacloud_logo_name-blue.png') no-repeat;
                    }
                }

                form {
                    width: 100%;
                    padding-top: 32px;

                    mat-form-field {
                        width: 100%;

                        @include conflow.media-breakpoint('xs') {
                            width: 80%;
                        }
                    }
                    
                    .remember-forgot-password {
                        font-size: 13px;
                        margin-top: 8px;

                        .remember-me {
                            margin-bottom: 16px
                        }

                        .forgot-password {
                            font-size: 13px;
                            font-weight: 600;
                            margin-bottom: 25px;
                            text-decoration: none;
                        }
                    }

                    .submit-button {
                        width: 220px;
                        height: 40px;
                        margin: 16px auto;
                        display: block;

                        @include conflow.media-breakpoint('xs') {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}
